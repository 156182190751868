:root {
  --background-color: #fafafa;
  --text-color: #000;
}

@font-face { 
  font-family: "MyFont"; 
  src: url("./montserrat-font.woff2"); 
} 

 


@tailwind base;
@tailwind components;
@tailwind utilities;

 
body {
  font-family: "MyFont", sans-serif;
}

.w-44 {
  width: 48%;
}

.text-xx {
  font-size: 10px;
}

.flexclass {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.border-r-w1 {
  border-right-width: 1px;
}
.minScreen {
  min-height: calc(100vh - 3.2rem);
}
gbtransparent {
  background-color: red;
}

span {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

input:invalid[focused="true"] {
  border: 1px solid red;
}

input:invalid[focused="true"] ~ span {
  display: block;
}
.sliderWidth {
  max-width: calc(100vw - 22rem);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

details summary::-webkit-details-marker {
  display:none;
}

summary {
  marker: "none";
    list-style: "none";
}